import { createMemo, Show, Suspense, useContext } from 'solid-js';
import { Navigate } from '@solidjs/router';
import { Meta, Title } from '@solidjs/meta';
import { ActivityIndicator, Container, Heading, Picture, Section } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { clientOnly } from '@solidjs/start';
import { z } from 'zod';
import { FacilityCtx } from '../../../../../../providers/facility';
import { getSchemaString } from '../../../../../../modules/schema/schema';
import { breadcrumbs } from '../../../../../../modules/schema/breadcrumb-list';
import { image } from '../../../../../../modules/schema/image';
import { webpage } from '../../../../../../modules/schema/webpage';
import { createSearchStore } from '../../../../../../modules/search-store';
import { timeFrameNameFromHours } from '../../../../../../modules/date-formatting';
import { teeTimeSearchSchema } from '../../../../../../components/tee-time-search';
import { TeeTimeSearchHeader } from '../../../../../../components/tee-time-search/header';
import {
	TeeTimeFilters,
	teeTimeFilterSchema,
	TeeTimeFiltersFAB,
} from '../../../../../../components/tee-time-search/filters';
import { Grid, GridThird, GridTwoThirds } from '../../../../../../components/layouts/grid';
import { ClosureMessage } from '../../../../../../components/closure-message';

const TeeTimeResults = clientOnly(async () => ({
	default: (await import('../../../../../../components/tee-time-search/results')).TeeTimeResults,
}));

const baseSchema = teeTimeSearchSchema.merge(teeTimeFilterSchema).extend({
	expand: z.array(z.string()).nullable(),
});

export function ReserveTeeTime() {
	const data = useContext(FacilityCtx);
	const [filters, setFilters] = createSearchStore(baseSchema);

	const address = createMemo(() => {
		const address = data()?.facility.metadata?.address;
		return address ? [address.street, address.city, [address.state, address.postalCode].join(' ')].join(', ') : null;
	});

	return (
		<Suspense>
			<Show when={data()?.facility}>
				{(facility) => (
					<Show when={facility().isBookable} fallback={<Navigate href={`/course/${facility().slug}`} />}>
						<Title>{`${facility()?.name} | Book tee times | Troon`}</Title>
						<Meta
							name="description"
							content={`Reserve golf tee times at ${facility()?.name} and earn Troon Rewards points.`}
						/>
						<script
							type="application/ld+json"
							innerText={getSchemaString([
								breadcrumbs(`/course/${facility().slug}/reserve-tee-time`, [
									{ name: 'Home', pathname: '/' },
									{ name: 'Courses', pathname: '/courses' },
									{ name: facility().name ?? '', pathname: `/course/${facility().slug}` },
									{ name: 'Reserve tee times', pathname: `/course/${facility().slug}/reserve-tee-time` },
								]),
								image(facility().metadata!.hero!.url ?? ''),
								webpage(`/course/${facility().slug}`, {
									title: `${facility.name} | Reserve tee times | Troon Rewards | Book tee times`,
								}),
							])}
						/>

						<div class="-mb-6 grow bg-neutral-100 md:-mb-12">
							<div class="bg-white">
								<Container class="flex-row items-center gap-3 pt-6 md:gap-8">
									<div class="flex aspect-square shrink-0 grow-0 basis-20 rounded-md border border-neutral bg-white p-2 md:basis-24 md:p-2">
										<Picture
											preload
											src={facility().metadata?.logo?.url}
											width={200}
											height={200}
											sizes="(min-width: 768px) 8rem, 4rem"
											mode="contain"
											class="w-full max-w-max rounded object-cover"
										/>
									</div>
									<div class="flex flex-col justify-around">
										<Heading as="h1" size="h4">
											{facility().name}
										</Heading>
										<address class="text-sm not-italic text-neutral-800" aria-label={`Address for ${facility.name}`}>
											{address()}
										</address>
									</div>
								</Container>
							</div>

							<TeeTimeSearchHeader
								trackQuery={false}
								hideQueryInput
								filters={filters}
								setFilters={setFilters}
								scrollHeader={
									<div class="flex gap-2 @container">
										<div class="hidden aspect-square shrink-0 grow-0 basis-16 rounded-md border border-neutral bg-white p-1 @md:flex">
											<Picture
												preload
												src={facility().metadata?.logo?.url}
												width={200}
												height={200}
												sizes="(min-width: 768px) 8rem, 4rem"
												mode="contain"
												class="w-full max-w-max rounded object-cover"
											/>
										</div>
										<div class="flex grow flex-col justify-center truncate">
											<Heading as="p" size="h4" class="truncate">
												{facility().name}
											</Heading>
											<p class="truncate text-sm text-neutral-800">
												{dayjs(filters.date).format('ddd, MMM D')} • {filters.players} player
												{filters.players > 1 ? 's' : ''} •{' '}
												{timeFrameNameFromHours(filters.startAt, filters.endAt, true)}
											</p>
										</div>
									</div>
								}
							/>

							<Container>
								<Section class="pt-0 lg:pt-8">
									<ClosureMessage notes={data()?.notes} closures={data()?.closures} />
									<Grid>
										<GridThird>
											<div
												// Manual top calculations are depend on the height of the TeeTimeSearchFields section
												// eslint-disable-next-line tailwindcss/no-arbitrary-value
												class="sticky top-[13.5rem] hidden max-h-[calc(100dvh-15rem)] flex-col gap-6 overflow-auto rounded border border-neutral bg-white p-6 lg:flex xl:top-[8.5rem] xl:max-h-[calc(100dvh-11rem)]"
											>
												<Heading as="h2" size="h5">
													Filter by
												</Heading>

												<TeeTimeFilters courses={facility().courses} filters={filters} setFilters={setFilters} />
											</div>
										</GridThird>
										<GridTwoThirds>
											<Suspense fallback={<ActivityIndicator />}>
												<TeeTimeResults
													filterByCourse
													facilities={[facility()]}
													filters={filters}
													setFilters={setFilters}
													singleFacility
												/>
											</Suspense>
										</GridTwoThirds>
									</Grid>
									<div class="lg:hidden">
										<TeeTimeFiltersFAB courses={facility().courses} filters={filters} setFilters={setFilters} />
									</div>
								</Section>
							</Container>
						</div>
					</Show>
				)}
			</Show>
		</Suspense>
	);
}
